<template>
  <div class="chat-conversation__loader">
    <div class="chat-conversation__loader-content" ref="chatLoader">
      <check-load-circle class="chat-conversation__loader-content--icon"/>
    </div>
  </div>
</template>

<script>
import { gsap } from 'gsap';

export default {
  mounted() {
    gsap.to(this.$refs.chatLoader, {
      duration: 0.75, rotate: 360, repeat: -1, ease: 'none',
    });
  },
};
</script>

<style scoped lang="scss">
  @import '~styles/components/chat/_chat.scss';
</style>
